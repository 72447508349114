import { Commit } from "vuex";
import { openErrorNotification, openSuccessNotification } from "@/components/Notification";
import { getPuitesopimukset, savePuitesopimus, getPuitesopimus } from "../api/puitesopimukset";
import { Puitesopimus } from "../@types/Puitesopimus";

interface State {
    puitesopimukset: Puitesopimus[];
    puitesopimuksetLoading: boolean;
    puitesopimus: Puitesopimus | null;
    puitesopimusLoading: boolean;
    saving: boolean;
    savingFailed: boolean;
}

export default {
    namespaced: true,
    state: (): State => ({
        puitesopimukset: [],
        puitesopimuksetLoading: false,
        puitesopimus: null,
        puitesopimusLoading: false,
        saving: false,
        savingFailed: false,
    }),
    mutations: {
        setPuitesopimukset(state: State, puitesopimukset: Puitesopimus[]) {
            state.puitesopimukset = puitesopimukset;
        },
        setPuitesopimuksetLoading(state: State, loading: boolean) {
            state.puitesopimuksetLoading = loading;
        },
        setPuitesopimus(state: State, puitesopimus: Puitesopimus) {
            state.puitesopimus = puitesopimus;
        },
        setPuitesopimusLoading(state: State, loading: boolean) {
            state.puitesopimusLoading = loading;
        },
        setSaving(state: State, saving: boolean) {
            state.saving = saving;
        },
        setSavingFailed(state: State, saving: boolean) {
            state.savingFailed = saving;
        },
    },
    actions: {
        async fetchPuitesopimukset({ commit }: { commit: Commit }) {
            try {
                commit("setPuitesopimuksetLoading", true);
                const puitesopimukset = await getPuitesopimukset();
                commit("setPuitesopimukset", puitesopimukset.results);
            } catch (err) {
                openErrorNotification("Puitesopimusten hakeminen epäonnistui");
            } finally {
                commit("setPuitesopimuksetLoading", false);
            }
        },
        async fetchPuitesopimusById({ commit }: { commit: Commit }, uuid: string) {
            try {
                commit("setPuitesopimusLoading", true);
                const puitesopimus = await getPuitesopimus(uuid);
                commit("setPuitesopimus", puitesopimus.results);
            } catch (err) {
                openErrorNotification("Puitesopimuksen hakeminen epäonnistui");
            } finally {
                commit("setPuitesopimusLoading", false);
            }
        },
        async savePuitesopimus(
            { commit }: { commit: Commit },
            {
                puitesopimus,
                showNotification,
            }: { puitesopimus: Puitesopimus; showNotification?: boolean }
        ) {
            try {
                commit("setSaving", true);
                const response = await savePuitesopimus(puitesopimus);
                commit("setPuitesopimus", response);
                if (showNotification) {
                    openSuccessNotification("Puitesopimus tallennettu onnistuneesti");
                }
                commit("setSavingFailed", false);
            } catch (err) {
                if (showNotification) {
                    openErrorNotification("Puitesopimuksen tallentaminen epäonnistui");
                }
                commit("setSavingFailed", true);
            } finally {
                commit("setSaving", false);
            }
        },
    },
};
