export async function getPalveluyksikot() {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    };
    const palveluyksikkoUrl = `${process.env.VUE_APP_API_URL}/palveluyksikko`;
    const response = await fetch(palveluyksikkoUrl, requestOptions);
    return await response.json();
}

export async function putPalveluyksikko(palveluyksikko: any) {
    const requestOptions = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        body: palveluyksikko,
    };
    const palveluyksikkoUrl = `${process.env.VUE_APP_API_URL}/palveluyksikko`;
    const response = await fetch(palveluyksikkoUrl, requestOptions);
    return await response.json();
}
