
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";

export default defineComponent({
    setup() {
        const store = useStore();

        const loading = computed(() => store.state.userLoading);

        return { loading };
    },
});
