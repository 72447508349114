import { ROLE } from "@/constants/roles";

const Module = () => import(/* webpackChunkName: "palveluhaku" */ "../Module.vue");
const Palveluhaku = () => import(/* webpackChunkName: "palveluhaku" */ "../views/Palveluhaku.vue");

const moduleRoute = {
    path: "/palveluhaku",
    name: "palveluhaku",
    component: Module,
    children: [
        {
            path: "",
            component: Palveluhaku,
        },
    ],
    meta: {
        requiresAuth: true,
        allowedRoles: [
            ROLE.TYONTEKIJA,
            ROLE.KUNTAMANAGER,
            ROLE.VALVOJA,
            ROLE.VALVOJAMANAGER,
            ROLE.PALVELUNTUOTTAJA,
        ],
    },
};

export default (router: any) => {
    router.addRoute(moduleRoute);
};
