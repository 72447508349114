const Module = () => import("../Module.vue");
const Puitesopimukset = () =>
    import(/* webpackChunkName: "puitesopimukset" */ "../views/Puitesopimukset.vue");
const Puitesopimus = () =>
    import(/* webpackChunkName: "puitesopimus" */ "../views/Puitesopimus.vue");
import { ROLE } from "@/constants/roles";

const moduleRoute = {
    path: "/puitesopimukset",
    name: "puitesopimukset",
    component: Module,
    children: [
        {
            path: "",
            component: Puitesopimukset,
        },
        {
            path: ":id",
            component: Puitesopimus,
        },
    ],
    meta: {
        requiresAuth: true,
        allowedRoles: [ROLE.TYONTEKIJA, ROLE.KUNTAMANAGER],
    },
};

export default (router: any) => {
    router.addRoute(moduleRoute);
};
