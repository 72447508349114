import { ValvontatiedotSearchParams } from "@/modules/valvontatiedot/@types/ValvontatiedotSearchParams";
import { Valvontatapahtuma } from "../@types/Valvontatapahtuma";

const url = `${process.env.VUE_APP_API_URL}/valvontatapahtuma`;

export async function getValvontatapahtumat(searchParams: ValvontatiedotSearchParams) {
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(searchParams),
    };
    const response = await fetch(`${url}haku`, requestOptions);
    return await response.json();
}

export async function saveValvontatapahtuma(valvontatapahtuma: Valvontatapahtuma) {
    const requestOptions = {
        method: valvontatapahtuma.uuid ? "PUT" : "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(valvontatapahtuma),
    };

    const response = await fetch(url, requestOptions);
    return await response.json();
}

export async function deleteValvontatapahtuma(uuid: string) {
    const requestOptions = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ uuid }),
    };

    return await fetch(url, requestOptions);
}
