import { Commit } from "vuex";
import { openErrorNotification, openSuccessNotification } from "@/components/Notification";
import {
    getValvontatapahtumat,
    saveValvontatapahtuma,
    deleteValvontatapahtuma,
} from "../api/valvontatapahtumat";
import { ValvontatapahtumaSearchResult } from "../@types/ValvontatapahtumaSearchResult";
import { Valvontatapahtuma } from "../@types/Valvontatapahtuma";
import { ValvontatiedotSearchParams } from "@/modules/valvontatiedot/@types/ValvontatiedotSearchParams";

interface State {
    valvontatapahtumat: ValvontatapahtumaSearchResult[];
    valvontatapahtumatLoading: boolean;
    searchParams: ValvontatiedotSearchParams | null;
    updatingValvontatapahtuma: boolean;
}

export default {
    namespaced: true,
    state: (): State => ({
        valvontatapahtumat: [],
        valvontatapahtumatLoading: false,
        searchParams: null,
        updatingValvontatapahtuma: false,
    }),
    mutations: {
        setValvontatapahtumat(state: State, valvontatapahtumat: ValvontatapahtumaSearchResult[]) {
            state.valvontatapahtumat = valvontatapahtumat;
        },
        setValvontatapahtumatLoading(state: State, loading: boolean) {
            state.valvontatapahtumatLoading = loading;
        },
        setSearchParams(state: State, searchParams: ValvontatiedotSearchParams) {
            state.searchParams = searchParams;
        },
        setUpdatingValvontatapahtuma(state: State, loading: boolean): void {
            state.updatingValvontatapahtuma = loading;
        },
    },
    actions: {
        async fetchValvontatapahtumat(
            { commit }: { commit: Commit },
            {
                searchParams,
                addedValvontatapahtuma,
            }: { searchParams: any; addedValvontatapahtuma?: boolean }
        ): Promise<void> {
            try {
                commit("setValvontatapahtumatLoading", !addedValvontatapahtuma);
                const valvontatapahtumat = await getValvontatapahtumat(searchParams);
                commit("setValvontatapahtumat", valvontatapahtumat.results);
                commit("setSearchParams", searchParams);
            } catch (err) {
                openErrorNotification("Valvontatapahtumien hakeminen epäonnistui");
            } finally {
                commit("setValvontatapahtumatLoading", false);
            }
        },
        async saveValvontatapahtuma(
            { commit, dispatch, state }: { commit: Commit; dispatch: any; state: any },
            valvontatapahtuma: Valvontatapahtuma
        ) {
            try {
                commit("setUpdatingValvontatapahtuma", true);
                await saveValvontatapahtuma(valvontatapahtuma);
                state.searchParams &&
                    dispatch("fetchValvontatapahtumat", {
                        searchParams: state.searchParams,
                        addedValvontatapahtuma: true,
                    });
                openSuccessNotification("Valvontatapahtuma tallennettu onnistuneesti");
                commit("setUpdatingValvontatapahtuma", false);
            } catch (err) {
                openErrorNotification("Valvontatapahtuman tallentaminen epäonnistui");
            }
        },
        async deleteValvontatapahtuma(
            { dispatch, state }: { dispatch: any; state: any },
            uuid: string
        ) {
            try {
                await deleteValvontatapahtuma(uuid);
                state.searchParams &&
                    dispatch("fetchValvontatapahtumat", {
                        searchParams: state.searchParams,
                        addedValvontatapahtuma: true,
                    });
                openSuccessNotification("Valvontatapahtuma poistettiin");
            } catch (err) {
                openErrorNotification("Valvontatapahtuman poistaminen epäonnistui");
            }
        },
    },
};
