export async function saveOrganisation(organisation: any) {
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(organisation),
    };

    const organisationUrl = `${process.env.VUE_APP_API_URL}/organisations`;
    const response = await fetch(organisationUrl, requestOptions);
    return await response.json();
}

export async function getOrganisations(valvoja?: boolean) {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    };
    const organisationUrl = `${process.env.VUE_APP_API_URL}/organisations${
        valvoja ? "?valvoja=true" : ""
    }`;
    const response = await fetch(organisationUrl, requestOptions);
    return await response.json();
}
