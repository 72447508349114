import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_q_page_container = _resolveComponent("q-page-container")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_q_layout = _resolveComponent("q-layout")!

  return (_openBlock(), _createBlock(_component_q_layout, { view: "hhh LpR fff" }, {
    default: _withCtx(() => [
      _createVNode(_component_Header),
      _createVNode(_component_q_page_container, null, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }),
      _createVNode(_component_Footer)
    ]),
    _: 3
  }))
}