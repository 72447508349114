import { Notify } from "quasar";

const notificationObj = {
    icon: "close",
    position: "bottom-left",
    actions: [{}],
};

function openNotification(message: string) {
    Notify.create({
        message,
        ...notificationObj,
    });
}

function openErrorNotification(message: string) {
    Notify.create({
        message,
        classes: "error",
        ...notificationObj,
    });
}

function openSuccessNotification(message: string) {
    Notify.create({
        message,
        classes: "success",
        ...notificationObj,
    });
}

export { openNotification, openErrorNotification, openSuccessNotification };
