import { Puitesopimus } from "../@types/Puitesopimus";

const url = `${process.env.VUE_APP_API_URL}/puitesopimus`;

export async function getPuitesopimus(uuid: string) {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    };
    const puitesopimuksetUrl = `${url}?uuid=${uuid}`;
    const response = await fetch(puitesopimuksetUrl, requestOptions);
    return await response.json();
}

export async function getPuitesopimukset() {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    };
    const response = await fetch(url, requestOptions);
    return await response.json();
}

export async function savePuitesopimus(puitesopimus: Puitesopimus) {
    const requestOptions = {
        method: puitesopimus.uuid ? "PUT" : "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(puitesopimus),
    };

    const response = await fetch(url, requestOptions);
    return await response.json();
}
