import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { Quasar, Notify } from "quasar";
import theme from "./theme";
import AppLayout from "./layouts/AppLayout.vue";

import palveluhakuModule from "./modules/palveluhaku";
import valvontatiedotModule from "./modules/valvontatiedot";
import organisationsModule from "./modules/organisations";
import palveluntuottajaModule from "./modules/palveluntuottaja";
import puitesopimuksetModule from "./modules/puitesopimukset";
import kilpailutusModule from './modules/kilpailutus';
import frontpageModule from "./modules/frontpage";
import saavutettavuusselosteModule from "./modules/saavutettavuusseloste";
import valvontakalenteriModule from "./modules/valvontakalenteri";

import { registerModules } from "./register-modules";

registerModules({
    palveluhaku: palveluhakuModule,
    valvontatiedot: valvontatiedotModule,
    organisations: organisationsModule,
    palveluntuottaja: palveluntuottajaModule,
    puitesopimukset: puitesopimuksetModule,
    kilpailutus: kilpailutusModule,
    frontpage: frontpageModule,
    valvontakalenteri: valvontakalenteriModule,
    saavutettavuusseloste: saavutettavuusselosteModule,
});

createApp(App)
    .use(router)
    .use(store)
    .use(Quasar, { plugins: { Notify }, ...theme })
    .component("AppLayout", AppLayout)
    .mount("#app");
