const Module = () => import("../Module.vue");
const Frontpage = () => import(/* webpackChunkName: "frontpage" */ "../views/Frontpage.vue");
import { ROLE } from "@/constants/roles";

const moduleRoute = {
    path: "/etusivu",
    name: "etusivu",
    component: Module,
    children: [
        {
            path: "",
            component: Frontpage,
        },
    ],
    meta: {
        requiresAuth: true,
        allowedRoles: [
            ROLE.TYONTEKIJA,
            ROLE.KUNTAMANAGER,
            ROLE.VALVOJA,
            ROLE.VALVOJAMANAGER,
            ROLE.PALVELUNTUOTTAJA,
            ROLE.SIHTEERI,
        ],
    },
};

export default (router: any) => {
    router.addRoute(moduleRoute);
};
