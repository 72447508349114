import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withScopeId as _withScopeId } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_q_footer = _resolveComponent("q-footer")!

  return (_openBlock(), _createBlock(_component_q_footer, {
    bordered: "",
    class: "row q-py-md items-center"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routerLinks, (link) => {
        return (_openBlock(), _createElementBlock("div", {
          key: link,
          class: "row item justify-center"
        }, [
          (_ctx.linkIsVisible(link.private))
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                class: "link text-dark-text",
                target: _ctx.linkTarget(link.newWindow),
                to: link.url
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(link.name), 1)
                ]),
                _: 2
              }, 1032, ["target", "to"]))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ]),
    _: 1
  }))
}