import { Commit } from "vuex";
import { ValvontatiedotSearchParams } from "@/modules/valvontatiedot/@types/ValvontatiedotSearchParams";
import { ValvontatiedotSearchResult } from "@/modules/valvontatiedot/@types/ValvontatiedotSearchResult";
import { openErrorNotification, openSuccessNotification } from "@/components/Notification";

export default {
    namespaced: true,
    state: {
        valvontatiedot: [],
        isLoadingResults: false,
        isLoadingValvontatieto: false,
        searchParams: null,
    },
    getters: {},
    mutations: {
        setPalveluyksikot(state: any, yksikot: any): void {
            state.palveluyksikot = yksikot;
        },
        setValvontatiedot(state: any, tiedot: ValvontatiedotSearchResult[]): void {
            state.valvontatiedot = tiedot.length
                ? tiedot.sort((v1, v2) =>
                      new Date(v1.control_date) > new Date(v2.control_date) ? -1 : 1
                  )
                : [];
        },
        updateValvontatiedot(state: any, tiedot: ValvontatiedotSearchResult[]): void {
            const valvonta: ValvontatiedotSearchResult | null = tiedot.length ? tiedot[0] : null;
            const index = state.valvontatiedot.findIndex(
                (v: ValvontatiedotSearchResult) => v.uuid === valvonta?.uuid
            );
            const newArr = [...state.valvontatiedot];
            newArr.splice(index, 1, valvonta);
            state.isLoadingValvontatieto = false;
            state.valvontatiedot = newArr;
        },
        setLoadingResults(state: any, loading: boolean): void {
            state.isLoadingResults = loading;
        },
        setLoadingValvontatieto(state: any, loading: boolean): void {
            state.isLoadingValvontatieto = loading;
        },
        setSearchParams(state: any, searchParams: ValvontatiedotSearchParams) {
            state.searchParams = searchParams;
        },
    },
    actions: {
        async getPalveluyksikot({ commit }: any): Promise<void> {
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            };
            const palveluyksikkoUrl = `${process.env.VUE_APP_API_URL}/palveluyksikko`;
            try {
                const response = await fetch(palveluyksikkoUrl, requestOptions);
                const palveluyksikot = await response.json();
                commit("setPalveluyksikot", palveluyksikot.results);
            } catch (err) {
                // TODO
            }
        },
        async saveValvontatiedot(
            { commit, dispatch, state }: { commit: Commit; dispatch: any; state: any },
            valvontatiedot: any
        ) {
            const requestOptions = {
                method: "POST",
                body: valvontatiedot,
            };
            const valvontatietoUrl = `${process.env.VUE_APP_API_URL}/valvontatieto`;
            try {
                const response = await fetch(valvontatietoUrl, requestOptions);
                await response.json();
                state.searchParams &&
                    dispatch("haku", {
                        searchParams: state.searchParams,
                        addedValvontatieto: true,
                    });
                openSuccessNotification("Valvontatiedot lisätty onnistuneesti");
            } catch (err) {
                openErrorNotification("Valvontatiedon lisääminen epäonnistui");
            }
        },
        async editValvontatiedot(
            { commit }: { commit: Commit },
            valvontatiedot: any
        ): Promise<void> {
            const requestOptions = {
                method: "PUT",
                body: valvontatiedot,
            };
            const valvontatietoUrl = `${process.env.VUE_APP_API_URL}/valvontatieto`;
            try {
                commit("setLoadingValvontatieto", true);
                const response = await fetch(valvontatietoUrl, requestOptions);
                const valvontatieto = await response.json();
                commit("updateValvontatiedot", valvontatieto);
                openSuccessNotification("Valvontatietojen muokkaus onnistui");
            } catch (err) {
                openErrorNotification("Valvontatietojen muokkaus epäonnistui");
            }
        },
        async haku(
            { commit }: { commit: Commit },
            {
                searchParams,
                addedValvontatieto,
            }: { searchParams: any; addedValvontatieto?: boolean }
        ) {
            // TODO: Extract these as a utility functions using generics so we can use correct type for searchParams
            // Helpful links
            // https://stackoverflow.com/questions/56568423/typescript-no-index-signature-with-a-parameter-of-type-string-was-found-on-ty
            // https://stackoverflow.com/questions/52856496/typescript-object-keys-return-string
            // as Array<keyof typeof searchParams>
            function removeEmpty(obj: any) {
                Object.entries(obj).forEach(
                    ([key, val]) =>
                        (val && typeof val === "object" && removeEmpty(val)) ||
                        ((val === null || val === "") && delete obj[key])
                );
                return obj;
            }

            removeEmpty(searchParams);

            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(searchParams),
            };

            const valvontatietoUrl = `${process.env.VUE_APP_API_URL}/valvontatietohaku`;

            try {
                // do not show loader when search is done after new valvontatieto has been added
                commit("setLoadingResults", !addedValvontatieto);
                const response = await fetch(valvontatietoUrl, requestOptions);
                const valvontatiedot = await response.json();
                commit("setValvontatiedot", valvontatiedot.results);
                commit("setSearchParams", searchParams);
            } catch (err) {
                openErrorNotification("Valvontatietojen hakeminen epäonnistui");
                // TODO
            } finally {
                commit("setLoadingResults", false);
            }
        },
    },
};
