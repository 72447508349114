
import { defineComponent, ref, markRaw, watch } from "vue";
import { useRoute } from "vue-router";
import AppLayoutDefault from "./AppLayoutDefault.vue";

export default defineComponent({
    setup() {
        const layout = ref();
        const route = useRoute();

        watch(
            () => route.meta,
            async (meta) => {
                try {
                    layout.value = markRaw(await getLayout(meta.layout as string));
                } catch (e) {
                    console.log(e);
                    layout.value = markRaw(AppLayoutDefault);
                }
            }
        );

        return { layout };
    },
});

async function getLayout(name: string) {
    const component = await import(`@/layouts/${name}.vue`);
    return component?.default || AppLayoutDefault;
}
