
import { defineComponent, computed } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
    setup() {
        const routerLinks = [
            { name: "Tietosuojaseloste", url: "/pdf/tietosuojaseloste.pdf", newWindow: true },
            { name: "Saavutettavuusseloste", url: "/saavutettavuusseloste" },
            { name: "Käyttöohje", url: "/pdf/kaytto-ohje.pdf", private: true, newWindow: true },
        ];

        const route = useRoute();
        const requiresAuth = computed(() => route.meta.requiresAuth);

        const linkIsVisible = (isPrivate?: boolean) =>
            (isPrivate && requiresAuth.value) || !isPrivate;

        const linkTarget = (target?: boolean) => {
            if (target) return "_blank";
        };

        return { routerLinks, linkIsVisible, linkTarget };
    },
});
