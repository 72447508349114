const url = `${process.env.VUE_APP_API_URL}/kilpailutus`;

export async function getKilpailutusasiakirjat() {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    };
    const response = await fetch(url, requestOptions);
    return await response.json();
}

export async function saveKilpailutusasiakirja(kilpailutusasiakirja: any) {
    const requestOptions = {
        method: "POST",
        body: kilpailutusasiakirja,
    };

    const response = await fetch(url, requestOptions);
    return await response.json();
}

export async function deleteKilpailutusasiakirja(uuid: string) {
    const requestOptions = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ uuid }),
    };

    return await fetch(url, requestOptions);
}
