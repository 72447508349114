
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { Role } from "@/@types/Role";
import { ROLE } from "@/constants/roles";
import useAuthorization from "@/utils/useAuthorization";

interface Path {
    name: string;
    path?: string;
    childRoutes?: Path[];
}

export default defineComponent({
    setup() {
        const store = useStore();
        const router = useRouter();
        const route = useRoute();
        const allRoutes = computed(() => router.getRoutes());

        const isVisibleForRole = (routePath: string | string[]): boolean => {
            const arr = Array.isArray(routePath) ? routePath : [routePath];
            const routeByPathArr = allRoutes.value.filter((route) =>
                arr.includes(route.name as string)
            );
            const allAllowedRoles = routeByPathArr.map((r: any) => r.meta.allowedRoles).flat();
            const { canAccess } = useAuthorization(allAllowedRoles as Role[]);
            return canAccess;
        };

        const routeChildPaths = (route: Path): string[] =>
            route.childRoutes!.map((p) => p.path as string);

        const routeSelected = (routePath: string | string[]): string => {
            const arr = Array.isArray(routePath) ? routePath : [routePath];
            return arr.some((path) => router.currentRoute.value.path.includes(path))
                ? "header-btn-selected"
                : "";
        };

        const signOut = () => {
            window.location.href = process.env.VUE_APP_LOGOUT_URL;
        };

        const isPalveluntuottaja =
            store.state.user?.user_organisation_role === ROLE.PALVELUNTUOTTAJA.id;

        const navigationRoutes: Path[] = [
            { path: "palveluhaku", name: isPalveluntuottaja ? "Yksiköt" : "Palveluhaku" },
            { path: "palveluntuottaja", name: "Organisaatio" },
            { path: "valvontatiedot", name: "Valvontatiedot" },
            { path: "valvontakalenteri", name: "Valvontakalenteri" },
            { path: "organisaatiot", name: "Organisaatiot" },
            {
                name: "Kilpailutus- ja hankintatiedot",
                childRoutes: [
                    { path: "puitesopimukset", name: "Puitesopimukset" },
                    { path: "kilpailutusasiakirjat", name: "Kilpailutusasiakirjat" },
                ],
            },
            { path: "", name: "Käyttäjähallinta" },
        ];

        const requiresAuth = computed(() => store.state.user);

        const userDetails = computed(() => {
            const { name, email, user_organisation_role } = store.state.user;
            const initials = name.split(" ").map((n: string) => n.charAt(0));
            return { initials: initials.join(""), name, email, user_organisation_role };
        });

        const userRole = computed(() =>
            Object.values(ROLE).find((v) => v.id === store.state.user.user_organisation_role)
        );

        const toFrontPage = () => {
            router.push("/etusivu");
        };

        return {
            signOut,
            userRole,
            userDetails,
            routeSelected,
            requiresAuth,
            isVisibleForRole,
            navigationRoutes,
            toFrontPage,
            routeChildPaths,
        };
    },
});
