const Module = () => import("../Module.vue");
const Valvontatiedot = () =>
    import(/* webpackChunkName: "valvontatiedot" */ "../views/Valvontatiedot.vue");
import { ROLE } from "@/constants/roles";

const moduleRoute = {
    path: "/valvontatiedot/:palveluyksikkoId?",
    name: "valvontatiedot",
    component: Module,
    children: [
        {
            path: "",
            component: Valvontatiedot,
        },
    ],
    meta: {
        requiresAuth: true,
        allowedRoles: [
            ROLE.TYONTEKIJA,
            ROLE.KUNTAMANAGER,
            ROLE.VALVOJA,
            ROLE.VALVOJAMANAGER,
            ROLE.PALVELUNTUOTTAJA,
            ROLE.SIHTEERI,
        ],
    },
};

export default (router: any) => {
    router.addRoute(moduleRoute);
};
