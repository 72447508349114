import { openErrorNotification } from "@/components/Notification";
import { getPalveluhakuSearchResults } from "@/modules/palveluhaku/api/palveluhaku";
import { putPalveluyksikko } from "@/modules/palveluhaku/api/palveluyksikot";
import { PalveluhakuParams } from "../@types/PalveluhakuParams";
import { Commit } from "vuex";

export default {
    namespaced: true,
    state: {
        searchResults: [],
        isLoadingResults: false,
    },
    getters: {},
    mutations: {
        setSearchResults(state: any, results: any) {
            state.searchResults = results;
        },
        setLoadingResults(state: any, loading: boolean) {
            state.isLoadingResults = loading;
        },
    },
    actions: {
        async fetchSearchResults({ commit }: { commit: Commit }, params: PalveluhakuParams) {
            try {
                commit("setLoadingResults", true);
                const results = await getPalveluhakuSearchResults(params);
                commit("setSearchResults", results);
            } catch (err) {
                openErrorNotification("Palveluhaun tulosten hakeminen epäonnistui");
            } finally {
                commit("setLoadingResults", false);
            }
        },
        async savePalveluyksikko(
            { commit }: { commit: Commit },
            palveluyksikko: any,
            params: PalveluhakuParams
        ): Promise<void> {
            try {
                commit("setLoadingResults", true);
                await putPalveluyksikko(palveluyksikko);
                // reload search results to get the edited data
                const results = await getPalveluhakuSearchResults({
                    name: "",
                    location: [],
                    municipality: [],
                    age: [],
                    palvelumuoto: [],
                    expertise: [],
                    availableRoom: false,
                    puitesopimus: false,
                    language: [],
                    service_language: null,
                });
                commit("setSearchResults", results);
            } catch (err) {
                openErrorNotification("Palveluyksikön muokkaus epäonnistui");
            } finally {
                commit("setLoadingResults", false);
            }
        },
    },
};
