const Module = () => import("../Module.vue");
const Palveluntuottaja = () =>
    import(/* webpackChunkName: "palveluntuottaja" */ "../views/Palveluntuottaja.vue");
import { ROLE } from "@/constants/roles";

const moduleRoute = {
    path: "/palveluntuottaja",
    name: "palveluntuottaja",
    component: Module,
    children: [
        {
            path: "",
            component: Palveluntuottaja,
        },
    ],
    meta: {
        requiresAuth: true,
        allowedRoles: [ROLE.PALVELUNTUOTTAJA],
    },
};

export default (router: any) => {
    router.addRoute(moduleRoute);
};
