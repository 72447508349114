const Module = () => import("../Module.vue");
const Valvontakalenteri = () =>
    import(/* webpackChunkName: "valvontakalenteri" */ "../views/Valvontakalenteri.vue");
import { ROLE } from "@/constants/roles";

const moduleRoute = {
    path: "/valvontakalenteri",
    name: "valvontakalenteri",
    component: Module,
    children: [
        {
            path: "",
            component: Valvontakalenteri,
        },
    ],
    meta: {
        requiresAuth: true,
        allowedRoles: [
            ROLE.TYONTEKIJA,
            ROLE.KUNTAMANAGER,
            ROLE.VALVOJA,
            ROLE.VALVOJAMANAGER,
            ROLE.SIHTEERI,
        ],
    },
};

export default (router: any) => {
    router.addRoute(moduleRoute);
};
