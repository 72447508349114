const Module = () => import("../Module.vue");
const Saavutettavuusseloste = () =>
    import(/* webpackChunkName: "saavutettavuusseloste" */ "../views/Saavutettavuusseloste.vue");

const moduleRoute = {
    path: "/saavutettavuusseloste",
    name: "saavutettavuusseloste",
    component: Module,
    children: [
        {
            path: "",
            component: Saavutettavuusseloste,
        },
    ],
};

export default (router: any) => {
    router.addRoute(moduleRoute);
};
