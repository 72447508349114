import variables from "@/styles/_variables.scss";

const theme = {
    config: {
        brand: {
            primary: variables.primary,
            secondary: variables.secondary,
            accent: variables.accent,
            dark: variables.darkText,
            positive: "#21BA45", // TODO
            negative: "#C10015", // TODO
            info: "#31CCEC", // TODO
            warning: "#F2C037", // TODO
        },
    },
};

export default theme;
