export async function getUserDetails() {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    };
    const authMeUrl = `${process.env.VUE_APP_API_URL}/me`;
    const response = await fetch(authMeUrl, requestOptions);
    return response;
}
