import { PalveluhakuParams } from "../@types/PalveluhakuParams";

export async function getPalveluhakuSearchResults(params: PalveluhakuParams) {
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
    };

    const palveluhakuUrl = `${process.env.VUE_APP_API_URL}/palveluhaku`;
    const response = await fetch(palveluhakuUrl, requestOptions);
    const data = await response.json();
    return data.results;
}
