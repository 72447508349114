const Module = () => import("../Module.vue");
const Kilpailutus = () => import(/* webpackChunkName: "kilpailutus" */ "../views/Kilpailutus.vue");
import { ROLE } from "@/constants/roles";

const moduleRoute = {
    path: "/kilpailutusasiakirjat",
    name: "kilpailutusasiakirjat",
    component: Module,
    children: [
        {
            path: "",
            component: Kilpailutus,
        },
    ],
    meta: {
        requiresAuth: true,
        allowedRoles: [
            ROLE.PALVELUNTUOTTAJA,
            ROLE.TYONTEKIJA,
            ROLE.KUNTAMANAGER,
            ROLE.VALVOJA,
            ROLE.VALVOJAMANAGER,
            ROLE.SIHTEERI,
        ],
    },
};

export default (router: any) => {
    router.addRoute(moduleRoute);
};
